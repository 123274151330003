import React, { useContext, useEffect, useMemo, useState } from 'react'
import Papa from 'papaparse'

const DataContext = React.createContext({})
const DataMethods = React.createContext({})

async function loadDefault(key) {
  try {
    const response = await fetch(`/data/${key}.csv`)
    const reader = response.body.getReader()
    const result = await reader.read() // raw array
    const decoder = new TextDecoder('utf-8')
    const csv = decoder.decode(result.value) // the csv text
    const results = Papa.parse(csv, { header: false }) // object with { data, errors, meta }
    const rows = results.data.map((row) => ({
      name: row[0],
      soundcloud: row[1],
      facebook: row[2],
      type: key,
    }))
    return rows
  } catch (e) {}
  return []
}

async function loadInitial() {
  const artists = await getItem('artists')
  const labels = await getItem('labels')
  return { artists, labels }
}

function getItem(key) {
  const tempLocal = localStorage.getItem(key)
  if (typeof tempLocal === 'string') {
    const parsed = JSON.parse(tempLocal)
    if (parsed?.length) return parsed
  }
  const tempDefault = loadDefault(key)
  if (typeof tempDefault === 'object') return tempDefault
  return null
}

function saveData(key, data) {
  localStorage.setItem(key, data)
}

function DataProvider({ children }: any) {
  const [artists, setArtists] = useState([])
  const [labels, setLabels] = useState([])

  useEffect(() => {
    async function load() {
      const { artists: tempArtists, labels: tempLabels } = await loadInitial()
      setArtists(tempArtists)
      setLabels(tempLabels)
    }
    load()
  }, [])
  useEffect(() => {
    if (artists?.length) saveData('artists', JSON.stringify(artists))
  }, [artists])
  useEffect(() => {
    if (labels?.length) saveData('labels', JSON.stringify(labels))
  }, [labels])

  const value = useMemo(() => ({ artists, labels }), [artists, labels])

  // Add new data item (updates state and local storage)
  // download csv/json
  // import custom csv/json

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
function DataMethodsProvider({ children }: any) {
  return <DataMethods.Provider value={[]}>{children}</DataMethods.Provider>
}

function useData() {
  const data = useContext(DataContext)
  const methods = useContext(DataMethods)
  if (data === undefined) {
    throw new Error('useData must be used within a DataProvider')
  }
  return { data, methods }
}

function DataProviders({ children }: any) {
  return (
    <DataProvider>
      <DataMethodsProvider>{children}</DataMethodsProvider>
    </DataProvider>
  )
}

export {
  DataContext,
  DataProviders,
  DataProvider,
  DataMethodsProvider,
  useData,
}
