import { TProfile } from '../types'

interface IProfileFeedProps {
  item: TProfile
}

export default function ProfileFeed(props: IProfileFeedProps) {
  const { item } = props
  return <div>ProfileFeed</div>
}
