import React, { useState } from 'react'
import ScreenLayout from 'components/layout/App'
import { useData } from 'hooks'
import Tabs from './components/Tabs'
import ProfilesList from './components/ProfileList'
import { TProfile } from './types'

const config = {
  tabs: ['all', 'artists', 'labels', 'promoters'],
  defaultTab: 'all',
}

export default function Profiles() {
  const { data }: { data: any } = useData()
  const [tabId, setTabId] = useState()
  const items: TProfile[] = tabId
    ? data?.[tabId]
    : data?.artists?.concat(data?.labels)
  return (
    <ScreenLayout>
      <Tabs tabId={tabId} setTabId={setTabId} config={config} />
      <ProfilesList items={items} />
    </ScreenLayout>
  )
}

/*
To add: 
- improve design (left colour indicator, less padding, no border)
- input field 
- input saves to sanity
- download csv option to "back up"
- add import option for loading custom csv (not from file)
- add "edit" option to existing fields to show input field
- open shows on hover / buttons on hover
- mark as empty / none for pages that don't exist

- Add artist profile / detail view with widgets etc
  - list of tracks of artist / label with play counts

*/
