import { TPost } from '../types'

interface IPostHeaderProps {
  item: TPost
}

export default function PostHeader(props: IPostHeaderProps) {
  const { item } = props
  return <div>PostHeader</div>
}
