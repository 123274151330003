export function groupBy(arr: any[] = [], criteria: (item: any) => any) {
  return typeof arr === 'object' && arr?.length
    ? arr.reduce(function (obj: any, item: any) {
        // Check if the criteria is a function to run on the item or a property of it
        const key =
          typeof criteria === 'function' ? criteria(item) : item[criteria]

        // If the key doesn't exist yet, create it
        if (!obj.hasOwnProperty(key)) {
          obj[key] = []
        }

        // Push the value to the object
        obj[key].push(item)

        // Return the object to the next item in the loop
        return obj
      }, {})
    : {}
}

export function orderByIndex(arr: any[] = []) {
  return typeof arr === 'object' && arr.length
    ? arr.sort((a: any, b: any) => a.index - b.index)
    : []
}

export function orderByField(arr: any[] = [], field: string) {
  return typeof arr === 'object' && arr.length
    ? arr.sort((a: any, b: any) =>
        a?.[field] > b?.[field] ? 1 : b?.[field] > a?.[field] ? -1 : 0,
      )
    : []
}

export const arrayToObject = (arr = [], keyField = 'id') =>
  Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })))

export const arrayToObjectNested = (arr, keyField, keyField2) => {
  if (!arr) {
    return {}
  }
  try {
    return Object.assign(
      {},
      ...arr.map((item) => ({ [item[keyField][keyField2]]: item })),
    )
  } catch (e) {}
}

export const objectToArray = (obj, keyField = 'id') => {
  if (!obj) {
    return []
  }
  return Object.keys(obj).map((key) => {
    const item = obj?.[key]
    if (keyField && item) {
      item[keyField] = key
    }
    return item
  })
}

// export function sortByDate(array: any[], field = 'date') {
//   return array.sort(
//     (date1: any, date2: any) =>
//       new Date(date2?.[field]) - new Date(date1?.[field])
//   )
// }
