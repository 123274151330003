import ScreenLayout from 'components/layout/App'
import PostHeader from './components/PostHeader'

import PostContent from './components/PostContent'
import { TPost } from './types'

export default function Post() {
  const item: TPost = { name: '' }
  return (
    <ScreenLayout>
      <PostHeader item={item} />
      <PostContent item={item} />
    </ScreenLayout>
  )
}
