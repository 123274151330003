import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

export default function ScreenHeader(props: any) {
  const { tabs: Tabs } = props
  console.log('ScreenHeader -> Tabs:', typeof Tabs, Tabs)
  const location = useLocation()
  const paths = location?.pathname?.split('/')
  const screenId = paths?.[1] ?? ''
  const pageId = paths?.[2] ?? ''

  return (
    <Box
      height={50}
      my={2}
      flexDirection="row"
      display="flex"
      alignItems="flex-end"
    >
      <Link to="/">
        <Typography variant="h3" component="h3">
          deacon{!!screenId && '/'}
        </Typography>
      </Link>
      {!!screenId && (
        <Link to={`/${screenId}`}>
          <Typography variant="h4" component="h4">
            {screenId + (pageId ? '/' : '')}
          </Typography>
        </Link>
      )}
      {!!pageId && (
        <Link to={`/${screenId}/${pageId}`}>
          <Typography variant="h4" component="h4">
            {pageId}
          </Typography>
        </Link>
      )}
      {typeof Tabs === 'object' && Tabs}
    </Box>
  )
}

// TODO: turn 2nd/3rd link into a drop down popover menu to switch between screens/pages
