import { TPost } from '../types'

interface IPostContentProps {
  item: TPost
}

export default function PostContent(props: IPostContentProps) {
  const { item } = props
  return <div>PostContent</div>
}
