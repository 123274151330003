import React, { useMemo, useState } from 'react'
// import Box from '@mui/material/Box'
// import FeedFilters from './components/FeedFilters'
// import FeedList from './components/FeedList'
import { Scrollbars } from 'react-custom-scrollbars'

import ScreenLayout from 'components/layout/App'
// import { useQuery } from '@tanstack/react-query'

// import { sanity, imageUrlBuilder } from 'util/sanity'
import useApi from '../../hooks/api'
// import { mapContext } from '../../components/Confirm'
import Tabs from '../../components/Tabs'
import { groupBy } from '../../util/array'
import FeedList from './components/FeedList'

const query = `
  *[ _type == 'mix']
`

const musicTabs = [
  { id: '', label: 'All' },
  { id: 'sets', label: 'Sets' },
  { id: 'mixes', label: 'Mixes' },
  { id: 'psytech', label: 'Psytech' },
  { id: 'downtempo', label: 'Downtempo' },
  { id: 'prog', label: 'Prog/Zenon' },
]

/*
tood: filters / hovers in responsive mode above player

item layout can be 
- squares/pics/insta with adaptive widths/heights
- posts
- carousels (responsive)
- either uses "header" embed or picture if post has picture available
- bandcamp embed
- 


PWA

*/

export default function Feed() {
  const { data } = useApi({ id: 'data' })
  console.log('Feed -> data:', data)

  const sorted = groupBy(data, (item: any) => item._type)
  const { mix } = sorted
  console.log('Feed -> sorted:', sorted)

  // const context: any = useMemo(() => mapContext(data), [data])
  // console.log('Feed -> context:', context)
  // console.log('Feed -> data:', data)
  const [state, setState] = useState('')
  // console.log('Feed -> mixList:', mixList)
  // const mixes =
  //   context?.mix?.items?.filter((item) => item?.links?.length > 0) ?? [] //mixList?.filter(item=>item?>)
  // console.log('Feed -> mixes:', mixes)
  //

  return (
    <>
      <ScreenLayout
      // tabs={<Tabs tabId={state} setTabId={setState} tabs={musicTabs} />}
      >
        <div
          className="flex flex-row h-[90vh]"
          // flexDirection="row"
          // display="flex"
          // height="90vh"
          // mt={2}
        >
          <Scrollbars>
            <div
            // flex={3}
            // display="flex"
            // overflowY="scroll"
            // height="60vh"
            // maxHeight="60vh"
            // mr={1.5}
            >
              {/* <Tabs tabId={state} setTabId={setState} tabs={musicTabs} />
              <FeedList items={mix} context={data} /> */}
            </div>
          </Scrollbars>
          <div className="h-auto max-w-[300px] min-w-[200px] w-full bg-[#242526] ml-1">
            {/* <FeedFilters items={mixes} context={context} /> */}
          </div>
        </div>
      </ScreenLayout>
      <div className="fixed bottom-0 h-20 w-[100hh] bg-black left-0 right-0">
        footer floating player
      </div>
    </>
  )
}

// overall feed / main layout
