import React, { useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'

export default function Tabs(props: any) {
  const { config, tabId, setTabId } = props
  const { tabs, defaultTab } = config

  const handleClick = useCallback(
    (id) => {
      setTabId(id === defaultTab ? '' : id)
    },
    [setTabId, defaultTab],
  )
  const selected = tabId ? tabId : defaultTab

  return (
    <Stack direction="row">
      {tabs?.map((tab) => (
        <Tab
          tab={tab}
          selected={selected}
          key={tab?.id ?? tab}
          onClick={handleClick}
        />
      ))}
    </Stack>
  )
}

function Tab(props: any) {
  const { tab, onClick, selected } = props
  const id = tab?.id ?? tab
  const handleClick = useCallback(() => {
    onClick(typeof id === 'string' ? id : '')
  }, [id, onClick])
  const isSelected = selected === id
  return (
    <Box onClick={handleClick} mr={2}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontWeight: isSelected ? '700' : '400' }}
      >
        {id}
      </Typography>
    </Box>
  )
}
