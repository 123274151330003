import React from 'react'

import { createRoot } from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles'
import Navigation from 'screens'
import AppLayout from 'components/layout/AppLayout'
import { red } from '@mui/material/colors'
import { DataProviders } from 'hooks'
import 'styles.css'
import {
  QueryClient,
  QueryClientProvider,
  // useQuery,
} from '@tanstack/react-query'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

const queryClient = new QueryClient()
// const { data: mixList } = useQuery('mixList', () => sanity.fetch(query))

// A custom theme for this app
const theme = createTheme({
  typography: {
    allVariants: {
      color: '#fff',
    },
  },
  palette: {
    primary: {
      main: '#eee',
    },
    font: {
      main: '#fff',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#1c1e21',
    },
  },
})

export default function App() {
  return (
    <AppLayout>
      <DataProviders>
        <Navigation />
      </DataProviders>
    </AppLayout>
  )
}

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
)
