import ScreenLayout from 'components/layout/App'
import ProfileHeader from './components/ProfileHeader'
import { TProfile } from './types'
import ProfileAbout from './components/ProfileAbout'
// import ProfileContent from './components/ProfileContent'
import ProfileFeed from './components/ProfileFeed'

export default function Profile() {
  const item: TProfile = { name: '' }
  return (
    <ScreenLayout>
      <ProfileHeader item={item} />
      <ProfileAbout item={item} />
      {/* <ProfileContent item={item} /> */}
      <ProfileFeed item={item} />
    </ScreenLayout>
  )
}

/*
To add: 
- improve design (left colour indicator, less padding, no border)
- input field 
- input saves to sanity
- download csv option to "back up"
- add import option for loading custom csv (not from file)
- add "edit" option to existing fields to show input field
- open shows on hover / buttons on hover
- mark as empty / none for pages that don't exist

- Add artist profile / detail view with widgets etc
  - list of tracks of artist / label with play counts

*/
