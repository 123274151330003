import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

export default function AppLayout({ children }: any) {
  return (
    <Container maxWidth="xl">
      <Box flexDirection="column" display="flex" maxHeight="100vh">
        {children}
      </Box>
    </Container>
  )
}
