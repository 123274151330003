import React from 'react'
import Box from '@mui/material/Box'
import ScreenHeader from './AppHeader'

export default function ScreenLayout({ children, tabs }: any) {
  return (
    <Box my={2} mx={4} flexDirection="column" display="flex" className="mb-20">
      <ScreenHeader tabs={tabs} />
      <Box>{children}</Box>
    </Box>
  )
}
