import { TProfile } from '../types'

interface IProfileAboutProps {
  item: TProfile
}

export default function ProfileAbout(props: IProfileAboutProps) {
  const { item } = props
  return <div>ProfileAbout</div>
}
