import { TProfile } from '../types'

interface IProfilesListProps {
  items: TProfile[]
}

export default function ProfilesList(props: IProfilesListProps) {
  const { items } = props
  return <div>ProfilesList</div>
}
