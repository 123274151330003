import { createClient } from '@sanity/client'
import ImageUrlBuilder from '@sanity/image-url'

// const sanity_url = 'https://deacon.sanity.studio/'
const projectId = 't37h49gt'

export const config = {
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'production',
  projectId, // process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  apiVersion: '2023-02-09',
  useCdn: process.env.NODE_ENV === 'production',
  token:
    process.env.NEXT_PUBLIC_SANITY_TOKEN ??
    'sklQntvKstHHCsiR8LLYd1neFY8OPJO5qg7YVZD4zpveAoGDN5klm9mvyDQVTU3PkSX0IEX3qNNM7kNTFssA8pH0tEIeXcxLD0D2DlEKN9jvofYPHpgcX3PrHUs8irUGGUypBu62hv8hDcWUXGY3CEvB4lRptFXXIpiph4USofz3L3UaRmKQ',
  withCredentials: true,
}

export const sanity = createClient(config)

// export function createData

// function callSanityApi() {
//   fetch('https://reqbin.com/echo/get/json', {
//     headers: { Authentication: `Bearer ${process.env.SANITY_API_KEY}` },
//   })
//     .then((resp) => resp.json())
//     .then((json) => console.log(JSON.stringify(json)))
// }

export const imageUrlBuilder = ImageUrlBuilder(sanity)
