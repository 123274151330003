import { TProfile } from '../types'

interface IProfileHeaderProps {
  item: TProfile
}

export default function ProfileHeader(props: IProfileHeaderProps) {
  const { item } = props
  return <div>ProfileHeader</div>
}
