import { Transaction } from '@sanity/client'
import { uuid } from '@sanity/uuid'
import { useMutation, useQuery } from '@tanstack/react-query'
import { sanity } from 'util/sanity'

const queries = {
  mix: `
  *[ _type == 'mix']
`,
  data: `
*[_type in ['artist','label', 'mix', 'track', 'link']]
`,
}

export default function useApi(props: any) {
  const { id } = props
  const query = queries?.[id]

  return useQuery([id + 'List'], () => sanity.fetch(query))
}

export function useUploadArtists(item) {
  console.log('useUploadArtists -> item:', item)
  const transaction = new Transaction().create({
    _type: 'artist',
    username: 'test1',
    name: 'Test1',
    soundcloud: 'asfa',
  })

  const mutation = useMutation(() =>
    sanity.mutate(transaction, {
      // onMutate(variables) {},
      // onSuccess(data) {},
      // onError(error: any) {},
    }),
  )

  return mutation
}

export async function uploadArtist(item) {
  console.log('useUploadArtists -> item:', item)
  const transaction = new Transaction().create({
    _type: 'artist',
    ...item,
    title: item?.soundcloud ?? item?.name,
  })

  const resp = await sanity.mutate(transaction, {
    // onMutate(variables) {},
    // onSuccess(data) {},
    // onError(error: any) {},
  })
  return resp
  // sanity.delete({
  //   query: `*[_type == "label"]`,
  // })
}

export async function uploadTrack(item, labels: any = [], artists: any = []) {
  console.log('uploadMix -> item:', item)
  const data = {
    ...item,
    artists: artists?.map((artist) => ({
      _ref: artist,
      _type: 'reference',
      _key: uuid(),
    })),
    labels: labels?.map((label) => ({
      _ref: label,
      _type: 'reference',
      _key: uuid(),
    })),
    title: item?.name,
  }
  console.log('uploadTrack -> data:', data)
  const transaction = new Transaction().create({
    _type: 'track',
    ...data,
  })

  const resp = await sanity.mutate(transaction, {
    // onMutate(variables) {},
    // onSuccess(data) {},
    // onError(error: any) {},
  })
  console.log('uploadTrack -> resp:', resp)
  return resp
}

export async function uploadMix(item) {
  console.log('uploadMix -> item:', item)
  const transaction = new Transaction().create({
    _type: 'mix',
    ...item,
    artists: [{ _ref: 'O93Jvjc4Q57YOPC70F75td', _type: 'reference' }],
    title: item?.name,
  })

  const resp = await sanity.mutate(transaction, {
    // onMutate(variables) {},
    // onSuccess(data) {},
    // onError(error: any) {},
  })
  return resp
}
