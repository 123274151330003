import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import Parser from './parser'
// import Music from 'screens/music'
// import Profiles from 'screens/profiles'
// import Home from 'screens/home'
// import Develop from 'screens/develop'
// import Blog from 'screens/blog'
// import Tools from 'screens/tools'
import Feed from './feed'
import Profiles from './profiles'
import Profile from './profile'
import Post from './post'
// import Labels from 'components/Labels'
// import Artists from 'screens/parser/components/Artists'
// import Confirm from 'components/Confirm'
// import { useState } from 'react'

export default function Navigation() {
  // const [parsed, setParsed] = useState(null)
  // const sharedPageProps = { parsed, setParsed }

  return (
    <Router>
      <Routes>
        {/* <Route path="/tools/profiles" element={<Profiles />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/develop" element={<Develop />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/music" element={<Music />} /> */}
        <Route path="/" element={<Feed />} />
        <Route path="/feed/:feedId?" element={<Feed />} />
        <Route path="/feed/:postId?" element={<Post />} />

        <Route path="/profiles/:profileType?" element={<Profiles />} />
        <Route path="/profile/:profileId?" element={<Profile />} />

        {/* <Route path="/parser/" element={<Parser />} /> */}
        {/* Move this to next navigation */}
        {/* <Route path="/tools/parser/labels" element={<Labels />} />
        <Route path="/tools/parser/artists" element={<Artists />} />
        <Route
          path="/tools/parser/confirm"
          element={<Confirm {...sharedPageProps} />}
        />
        <Route path="/tools/parser" element={<Parser {...sharedPageProps} />} /> */}
      </Routes>
    </Router>
  )
}
